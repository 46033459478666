/* src/LandingPage.css */
.landing-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: grey;
    flex-direction: column;
  }
  
  .logo {
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }

  .accenture-logo {
    width: 360px;  /* Adjust as needed */
    margin-bottom: 30%;
  }

  .change-logo {
    width: 260px;  /* Adjust as needed */
  }