

.App {
  height: 100vh;
  width: 100vw;
  font-family: 'Graphik-Regular';
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.trans-box {
  width: 100%;
}

.row-padding {
  /* padding: 0 5%; */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.schedule-box {
  font-family: 'Graphik-Regular';
  font-size: 18px;
}



.button-row {
    display: flex;
    justify-content: center;
}

.button-row button {
  height: 75px;
  width: 301px;
  border-radius: 10px;
  border: 2px #DCAFFF;
  background: #A100FF;
  color: #FFF;
  font-family: "Graphik-Bold";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 25px;
}

.schedule-box {
  border-radius: 8px;
  border: 2px solid #A100FF;
  background: #FBFAFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 24pt 2%;
  padding: 20px 24px 20px;
}
.schedule-box ul {
  color: #000;
  font-family: "Graphik-Regular";
  font-size: 18px;
  line-height: 27px; /* 150% */
  padding-left: 18pt;
}
.schedule-subtitle {
  color: #6D00B4;
  font-family: "Graphik-Semibold";
  font-size: 24px;
  line-height: 27px; /* 112.5% */
  margin-bottom: 24px;
}

.schedule-semibold-it {
  font-family: 'Graphik-Semibold-Italic';
}

.schedule-semibold {
  font-family: 'Graphik-Semibold';
}

.dontmiss-semi {
  font-family: 'Graphik-Semibold';
  font-size: 22px;
}

.make-sure {
  font-size: 22px;
}

.tour > .schedule-subtitle {
  font-size: 20px;
  color: #6D00B4;
  margin-bottom: 0;
}

.box.health-box.tour {
 background-color: #FFF!important;
 border: 2px solid #E5ECED!important;
 background: #FFF!important;
}

.options {
  color: #6D00B4;
  font-family: Graphik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
}

.schedule-bold {
  font-family: 'Graphik-Bold';
}

.schedule-addy {
  font-family: 'Graphik-Regular-Italic';
  color: #A100FF;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.header-row {
  /* background-color: rebeccapurple; */
  width: 100vw;
  /* height: 20vh; */
  object-fit: fill;
  /* background-color: #282c34; */
}

.box {
  border: 2px solid rgba(229, 236, 237, 1);
  border-radius: 10px;
  align-self: center;
  margin-bottom: 5%;
}

.row {
  /* padding: 50px; */
  /* background-color: rgb(211, 218, 216); */
  width: 100%;
  align-self: center;
}

.row-content {
  padding: 20px;
  display: flex;
  /* flex-grow: 1; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  /* width: 40%;; */
  font-family: 'Graphik-Regular';
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;

}

.two-row {
  /* background-color: #f2f2f2; */
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.left-col {
  width: 40%;
  flex-grow: 2;
}

.right-col {
  background-color: rebeccapurple;
  width: 60%;
  /* flex-grow: 2; */

}

.row-padding {
  /* padding: 0; */
}

.gray-bg {
  background-color: #f2f2f2;
}

.dark-gray-bg {
  background-color: #d6d6d6;
}

.sub-heading {
  font-family: 'Graphik-Regular';
  color: #9f00fb;
  font-size: 18px;
  align-self: flex-start;
}

.footer-row {
    /* background-color: #9f00fb; */
    background-image: url('../assets/pga_mobile_footer.png');
    background-size: cover;
    background-position-x: left;
    background-repeat: no-repeat; /* Do not repeat the image */
    aspect-ratio: 215 / 68;
    }

.navbar-bg-img {
  height: 100%;
}

.title {
  color: #9f00fb;
  font-size: x-large;
  font-family: 'Graphik-Semibold';
  font-weight: 200px;
  align-self: flex-start;
  padding: 5%;
}

.qr-subtitle {
  font-size: 20pt;
  font-family: 'Graphik-Semibold';
  font-weight: 600;
  color: #9f00fb;
  padding: 5% 0 0 0;
  margin-bottom: 24px;

}

.app-download {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  margin: 5% 0;
  gap: 5px;
  a {
    /* width: 50%; */
  }
}

.app-icon {
  width: 100%;;
}

.table-d {
  font-family: 'Graphik-Bold';
  white-space: nowrap;
  float: inline-start;
}

.qr-switch {
  color: #9f00fb;
  /* background-color: #ffffff; */

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  /* width: 20%; */
}

.qr-box {
}

.qr-left {
  text-align: left;
}

.qr-row {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  white-space: pre-wrap;
}

.subtitle {
  /* color: #7803bc; */
  color: #9f00fb;
  /* margin-left: 5%; */
  font-size: large;
  font-family: 'Graphik-Bold';
  font-weight: 600;
  align-self: flex-start;
  /* align-self: center; */
  /* padding: 5% 0 0 5%; */
}

.trans-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.trans-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  gap: 20px;
  /* background-color: #2196F3; */
}

.health-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.see-soon {
  background-image: url('../assets/bg1.svg');
  background-size: cover;
  /* width: 100%; */
  padding: 5%;
  /* background: radial-gradient(2451.47% 59891.07% at 139.72% 76.88%, #68C4D1 0.5%, #6D00B4 49.5%, #290C57 87.5%) warning: gradient uses a rotation that is not supported by CSS and may not behave as expected; */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 48px;

  a {
    color: white;
    text-decoration: none;
  }

}

.forecast {
  text-decoration: underline;
  line-height: 20px;
}

.see-soon-weather {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  align-self: center;
  font-size: medium;
  img {
    width: 10%;
  }
}

.see-soon-title {
  font-family: 'Graphik-Bold';
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;

}

.see-soon-subtitle {
  font-family: 'Graphik-Regular';
  font-size: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
}

.qr-box {
  display: flex;
  justify-self: flex-start;
  align-items: center;
  justify-content: center;
  background: rgba(229, 236, 237, 1);

  a {
    font-family: 'Graphik-Bold';
  }
}

.bottom-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;

}

.navbar-bg {
  /* background-color: #9f00fb; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 68px 0;
}

.navbar-bg.csuite-class{
  background-image: url('../assets/pga_mobile-aug29_banners.png');
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat; /* Do not repeat the image */
  height: 100%;
}

.navbar-bg.ute-class{
  background-image: url('../assets/pga_mobile-aug27_header.png');
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat; /* Do not repeat the image */
  height: 100%;
}

.navbar-left {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 50%;
  height: 100%;

}

.navbar-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  /* background-color: #b1b7bc; */
  width: 50%;
}

.see-you {
  width: 40vw;
  object-fit: scale-down;
}

.see-date {
  width: 50vw;
  object-fit: scale-down;
  margin-top: -30px;
}

.see-vegas-span {
  width: 90%;
  display: flex;
  align-items: center;
}

.see-in-vegas {
  width: 80%;
}

.see-vegas {
  width: 30%;
  object-fit: scale-down;
}

.vector {
  height: 30%;
  justify-self: flex-end;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #9f00fb;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #9f00fb;
}

input:focus + .slider {
  box-shadow: 0 0 1px #9f00fb;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.trans-header {
  color: #9f00fb;
}

.trans-wrapper {
  width: 100%;
}

.second-box {
  display: flex;
  flex-direction: column;
}

.see-soon-inner {
  padding: 2% 0;

}

.underline {
  text-decoration: underline;
}

.course-guide-box {
  height: 132px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid #EBF0F1;
  background: #E5ECED;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.course-guide-box p {
  color: #6D00B4;
  font-family: "Graphik-Bold";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  margin: 0;
}

.small-title {
  color: #A100FF;
  font-family: "Graphik-Regular";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.health-box ul {
  margin-left: 24pt;
}

.info-row {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #6D00B4;
  padding: 4rem 0;
  align-items: center;
  line-height: 20px;
  background-color: #E6ECED;
  padding-bottom: 0;
}

.info-row ul {
  width: 70%;
  line-height: 28px;
}

.info-row ul li {
  display: inline-block;
  text-transform: uppercase;
}

.info-row ul>li:before{
  content: "";
  display: list-item;
  position: absolute;
}

.list-title {
  font-family: 'Graphik-Semibold';
  font-size: large;
  text-transform: uppercase;
}

.health-box {
  width: 96%;
}



@media only screen and (min-width: 768px) {
  .see-soon-inner {
    width: 600px;
    font-size: 40px;

  }

  .qr-switch {
    background-color: #ffffff;
    padding: 2%;
    width: 300px;
    margin-bottom: 2%;
    font-size: 15px;
    font-family: 'Graphik-Bold';
    img {
      width: 80%;
      border: 2px solid #E5ECED
    }
  }

  .qr-span {
    display: flex;
    gap: 10px;
  }

  .qr-row {
    flex-wrap: wrap;
  }

  .title {
    font-size: 30px;
    padding: 2%;
  }

  .subtitle {
    font-size: 20px;
  }
  .footer-row {
    background-image: url('../assets/pga_desktop_footer.png');
    aspect-ratio: 18 / 1;
    background-size: cover;
    background-position-x: left;
    background-repeat: no-repeat; /* Do not repeat the image */

  }
}


@media only screen and (min-width: 1200px) {
  .see-soon-inner {
    width: 700px;
    font-size: 40px;

  }

  .trans-wrapper {
    width: 45%;
  }

  .second-box {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .navbar-bg {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .navbar-bg.ute-class {
    background-image: url('../assets/pga_desktop-aug27_header.png');
    background-size: cover;
    background-position-x: left;
  }

  .navbar-bg.csuite-class {
    background-image: url('../assets/pga_desktop-aug29_header.png');
    background-size: cover;
    background-position-x: left;
  }

  .info-row ul li {
    margin: 0 20px;
  }

  .trans-container {
    /* width: 25%; */
    width: 45%;
  }

  .see-vegas-span {
    width: 60%;
    display: flex;
    align-items: center;
  }

  .see-in-vegas {
    width: 50%;
  }

  .see-vegas {
    width: 20%;
  }


  .see-soon-title {
    font-size: 70px;
    line-height: 90px;
  }

  .title {
    padding: 0;
    font-size: 30px;
    margin-left: 24pt;
    margin-bottom: 24px;
    margin-top: 24px;
  }

  /* .health-row.row > .title {
    margin-top: 40px;
  }

  .header-row.row > .title.tour-title {
    margin-top: 24px;
  } */

  .trans-row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-around;
    gap: 16px;
    height: auto;
  }

  .tw-third > .trans-container {
    width: 30%;
  }

  div.transportation-section > div.trans-row.row.tw-third > div > div > div > ul {
    line-height: 27px;
  }

  .tw-third.trans-row {
    justify-content: space-evenly;
  }

  .trans-header {
    margin: 0;
  }

  .trans-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 25%; */
    height: 100%;
    padding: 1%;
  }

  .box {
    align-self: flex-start;
  }

  .health-box {
    width: 95%;
    margin-left: 24pt;
    font-size: 80px;
    line-height: 26px;

    font-size: large;

    .row-content {
      align-items: start;
    }
  }

  .bottom-row {
    flex-direction: row;
    padding: 0%;
  }

  .row-content {
    font-size: 20px;
    line-height: 35px;
  }

  .qr-box {
    /* align-self: flex-end; */
    /* margin-right: 5%; */
    width: auto;
    padding-bottom: 32px;
  }

  .qr-left {
    width: 70%;
    text-align: left;
  }
  

  .subtitle{
    font-size: 20px;
    line-height: 20px;
  }

  .qr-subtitle {
    font-size: 40px;
    line-height: 40px;
    padding-top: 0px;
    padding-bottom: 25px;
  }

  .see-soon-weather {

    justify-content: center;
    gap: 20px;
    img {
      width: 5%;
    }
  }

  .qr-row {
    flex-direction: row;
  }

  .qr-switch {
    background-color: #ffffff;
    padding: 2%;
    margin-bottom: 2%;
    font-size: 15px;
    font-family: 'Graphik-Bold';
    img {
      width: 80%;
      border: 2px solid #E5ECED
    }
  }

  .info-row ul {
    width: 50%;
    line-height: 28px;
  }


  .qr-span {
    display: flex;
    gap: 10px;
    white-space: nowrap;
  }

  .footer-row {
    background-image: url('../assets/pga_desktop_footer.png');
    aspect-ratio: 18 / 1;
    background-size: cover;
    background-position-x: left;
    background-repeat: no-repeat; /* Do not repeat the image */

  }


  .box {
    margin-bottom: 0;
  }

  .see-you {
    width: 30%;
  }



  .qr-switch {
    width: 300px;
  }

  .button-row {
    margin-top: 48px!important;
    margin-bottom: 0!important;    
  }

  .button-row button {
    margin-bottom: 0;
  }
  .box.schedule-box.tour {
    background-color: #FFF!important;
    border: 2px solid #E5ECED!important;
    background: #FFF!important;
    box-shadow: none!important;
   }
   
   
  .schedule-box {
    border-radius: 8px;
    border: 2px solid #A100FF;
    background: #FBFAFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 24pt 2%;
    padding: 24px 50px 36px;
  }
  .schedule-box ul {
    color: #000;
    font-family: "Graphik-Regular";
    font-size: 18px;
    line-height: 27px; /* 150% */
    padding-left: 18pt;
    margin-top: 0;
  }
  .schedule-subtitle {
    color: #6D00B4;
    font-size: 24px;
    line-height: 27px;
  }
  .tour-title.title {
    align-self: flex-start!important;
    text-align: left!important;
    padding-top: 0;
  }
  .health-row.row.cvent-adj > .title.tour-title {
    margin-top: 56px;
  }
  ul#weekend-ul  {
    margin-bottom: 0;
  }
  .course-guide-box {
    margin: 0 24pt;
  }

  .mar-100 {
    margin-top: 100px;
  }
  
}

.semi-bold-purp {
  font-family: "Graphik-Semibold";
  color: #A100FF;
}

.semi-bold {
  font-family: "Graphik-Semibold";
}

.italic-medium {
  font-family: "Graphik-Medium-Italic"
}

.graphik-mid-purp {
  font-family: "Graphik-Medium";
  color: #6D00B4;
}

.graphik-mid {
  font-family: "Graphik-Medium";
}

.graphik-regular-purp {
  font-family: "Graphik-Regular";
  color: #A100FF;
}

.graphik-italic-regular {
  font-family: "Graphik-Regular-Italic";
}

.address {
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 24px;
}

.trans-box > .row-content > ul > li {
  margin-top: 16px;
}

.button-row {
  margin-top: 24px;
  margin-bottom: 24px;    
}

.tour {
  margin-bottom: 24px;
  line-height: 26px;
}

.tour-title.title {
  align-self: center;
  text-align: center;
}

.rules {
  padding: 24px;
}

.rules ul {
  margin: 0;
}

.option {
  font-family: "Graphik-Medium";
  color: #A100FF;
}

.cvent-change {
  margin-top: 56px!important;
}

.box.schedule-box.tour {
  background-color: #FFF!important;
  border: 2px solid #E5ECED!important;
  background: #FFF!important;
  box-shadow: none!important;
 }

