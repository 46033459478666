

body {
  margin: 0;
}

:root {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

@font-face {
  font-family: 'Graphik-Regular';
  src: url('./assets/fonts/Graphik-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Graphik-Bold';
  src: url('./assets/fonts/Graphik-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Graphik-Super';
  src: url('./assets/fonts/Graphik-Super.ttf') format('truetype');
}

@font-face {
  font-family: 'Graphik-Black';
  src: url('./assets/fonts/Graphik-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Graphik-Extralight';
  src: url('./assets/fonts/Graphik-Extralight.ttf') format('truetype');
}

@font-face {
  font-family: 'Graphik-Light';
  src: url('./assets/fonts/Graphik-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Graphik-Medium';
  src: url('./assets/fonts/Graphik-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Graphik-Semibold';
  src: url('./assets/fonts/Graphik-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Graphik-Thin';
  src: url('./assets/fonts/Graphik-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Graphik-Regular-Italic';
  src: url('./assets/fonts/Graphik-Regular-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Graphik-Bold-Italic';
  src: url('./assets/fonts/Graphik-Bold-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Graphik-Super-Italic';
  src: url('./assets/fonts/Graphik-Super-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Graphik-Black-Italic';
  src: url('./assets/fonts/Graphik-Black-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Graphik-Extralight-Italic';
  src: url('./assets/fonts/Graphik-Extralight-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Graphik-Light-Italic';
  src: url('./assets/fonts/Graphik-Light-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Graphik-Medium-Italic';
  src: url('./assets/fonts/Graphik-Medium-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Graphik-Semibold-Italic';
  src: url('./assets/fonts/Graphik-Semibold-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Graphik-Thin-Italic';
  src: url('./assets/fonts/Graphik-Thin-Italic.ttf') format('truetype');
}



